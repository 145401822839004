import "@babel/polyfill";
import Glide, { Autoplay, Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import Accordion from 'accordion-js';
import i18n from './i18n'
import lozad from 'lozad'
import { ValidationObserver, ValidationProvider, localize, extend } from 'vee-validate'
import { required, email, confirmed, min } from 'vee-validate/dist/rules'

localize({
    nl: {
        messages: {
            required: 'Dit veld is verplicht.',
            text: 'Tekst moet langer zijn dan 1 karakter.',
            email: 'Geef een geldig e-mailadres op.',
            url: 'Geef een geldige url op.',
            phone: 'Geef een geldig telefoonnummer op.',
            confirmed: 'De wachtwoorden komen niet overeen.',
            min: 'Minstens 6 karakters'
        }
    },
    fr: {
        messages: {
            required: 'Ce champ est obligatoire.',
            text: 'Le texte doit comporter plus d\'un caractère.',
            email: 'Veuillez fournir une adresse email valide',
            url: 'Veuillez fournir une URL valide.',
            phone: 'Veuillez fournir un numéro de téléphone valide.',
            confirmed: 'Le mot de passe ne correspond pas',
            min: 'Minstens 6 karakters'
        }
    }
})

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: function (el) {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) {
                img[0].style.objectPosition = cropPos;
            }
        }
    }
});

import styles from '../css/app.pcss';

// App main
const main = async() => {
    const fontawesome = await import ( /* webpackChunkName: "fontawesome" */ '@fortawesome/fontawesome-free/css/all.css');
    const glideCss = await import ( /* webpackChunkName: "glide" */ '@glidejs/glide/dist/css/glide.core.css');
    //const accordionCss = await import ( /* webpackChunkName: "accordion" */ 'accordion-js/dist/accordion.min.css');
    const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios');
    const { default: Vuex } = await import(/* webpackChunkName: "vuex" */ 'vuex');
    const { default: Vue } = await import ( /* webpackChunkName: "vue" */ 'vue');
    const VueCookies = await import ( /* webpackChunkName: "vuecookies" */ 'vue-cookies');
    const Scripts = await import ( /* webpackChunkName: "scripts" */ './scripts.js');
    const { default: store } = await import(/* webpackChunkName: "store" */ './store/store.js');

    //const { default: ValidationObserver } = await import(/* webpackChunkName: "validationobserver" */ 'vee-validate');
    //const { default: ValidationProvider } = await import(/* webpackChunkName: "validationprovider" */ 'vee-validate');
    //const { default: localize } = await import(/* webpackChunkName: "localize" */ 'vee-validate');
    //const { default: extend } = await import(/* webpackChunkName: "extend" */ 'vee-validate');
    //const { default: required } = await import(/* webpackChunkName: "required" */ 'vee-validate/dist/rules');
    //const { default: email } = await import(/* webpackChunkName: "email" */ 'vee-validate/dist/rules');
    //const { default: confirmed } = await import(/* webpackChunkName: "confirmed" */ 'vee-validate/dist/rules');

    // Get CSRF Token
    window.axios = axios;
    let csrfToken = document.head.querySelector('meta[name="csrf-token"]');
    // Add to axios requests
    if(csrfToken) {
        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content;
    } else {
        console.error('CSRF token not found.');
    }

    // Start vuex
    window.Vuex = Vuex;
    Vue.use(Vuex);

    // Vee validate
    extend('email', email)
    extend('required', required)
    extend('confirmed', confirmed)
    extend('min', min)

    Vue.component('ValidationObserver', ValidationObserver)
    Vue.component('ValidationProvider', ValidationProvider)

    // Set currency
    let currentCurrency = 'EUR';
    let currentLocale = 'nl-NL';
    if(document.documentElement.lang === "en") {
        currentCurrency = 'GBP';
        currentLocale = 'en-GB';
    }

    // Add Global filters to Vue
    Vue.filter('currency', (value) => {
        var formatter = new Intl.NumberFormat(currentLocale, {
            style: 'currency',
            currency: currentCurrency,
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    });

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ["${","}"],
        components: {
            'statusbar': () => import(/* webpackChunkName: "statusbar" */ '../vue/Statusbar.vue'),
            'cartminiteaser': () => import(/* webpackChunkName: "cartminiteaser" */ '../vue/CartMiniTeaser.vue'),
            'cart': () => import(/* webpackChunkName: "cart" */ '../vue/Cart.vue'),
            'inlinecart': () => import(/* webpackChunkName: "inlinecart" */ '../vue/InlineCart.vue'),
            'backinstock': () => import(/* webpackChunkName: "backinstock" */ '../vue/BackInStock.vue')
        },
        store: new Vuex.Store(store),
        i18n,
        data: {
            openMobile: false,
            cookieConsented: false,
            newShippingAddress: false,
            newBillingAddress: false,
            cart: {
                shippingMethodHandle: null,
                shippingAddressId: null,
                billingAddressId: null
            },
            customer: {},
            addresses: {},

            newPassword: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address1: '',
            zipCode: '',
            city: ''
        },
        methods: {
            toggleMobile(e) {
                e.preventDefault();
                this.openMobile = !this.openMobile;
            },
            cookieConsent(e) {
                this.cookieConsented = true;
                VueCookies.set('cookieConsent', true);
            },
            onSubmit(e) {
                e.target.submit()
            },
            getCart() {
                let prefix = (document.documentElement.lang !== document.head.querySelector('meta[name="default-lang"]').content) ? '/' + document.documentElement.lang : '';
                window.axios({
                    method: 'get',
                    //url: prefix + '/actions/commerce/cart/get-cart',
                    url: prefix + '/actions/commerce-utilities-module/cart/get-cart',
                    responseType: 'json',
                }).then((response) => {
                    console.log(response.data)
                    this.cart = response.data.cart
                    this.getCustomer()
                    this.setCartDefaults()
                });
            },
            getCustomer() {
                let prefix = (document.documentElement.lang !== document.head.querySelector('meta[name="default-lang"]').content) ? '/' + document.documentElement.lang : '';
                window.axios({
                    method: 'get',
                    url: prefix + '/actions/commerce-utilities-module/customers/get-customer',
                    responseType: 'json'
                }).then((response) => {
                    console.log(response.data)
                    this.customer = response.data.customer
                    this.addresses = response.data.addresses
                    if(this.addresses.length == 0) {
                        this.newShippingAddress = true
                        this.newBillingAddress = true
                    }
                });
            },
            setCartDefaults() {
                if(this.cart.shippingMethodHandle != 'pickup') {
                    this.cart.shippingMethodHandle = 'shipping'
                }
                if(this.cart.shippingAddressId == null && this.customer.primaryShippingAddressId != null) {
                    this.cart.shippingAddressId = this.customer.primaryShippingAddressId
                    this.newShippingAddress = false
                }
                if(this.cart.billingAddressId == null && this.customer.primaryBillingAddressId != null) {
                    this.cart.billingAddressId = this.customer.primaryBillingAddressId
                    this.newBillingAddress = false
                }
                if((this.cart.pickupLocationId == null || this.cart.pickupLocationId == undefined) && document.querySelector('[name="fields[pickupLocation][0]"]') != null) {
                    this.cart.pickupLocationId = document.querySelector('[name="fields[pickupLocation][0]"]').value
                }
            },
            deleteLineItem(id) {
                document.getElementById('remove-' + id).value = 1;
                let data = new FormData(document.getElementById('form-cart'));

                axios.post('.', data)
                    .then(success => {
                        location.reload();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            toCheckout(redirect) {
                let data = new FormData(document.getElementById('form-cart'));

                axios.post('.', data)
                    .then(success => {
                        location = redirect;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            togleNewShippingAddress() {
                this.newShippingAddress = !this.newShippingAddress;
                this.cart.shippingAddressId = null;
            },
            togleNewBillingAddress() {
                this.newBillingAddress = !this.newBillingAddress;
                this.cart.billingAddressId = null;
            }
        },
        computed: {
            newCustomer: function() {
                if(this.addresses.length == 0)
                    return true
                return false
            },
            shippingAddressId: function() {
                return this.cart.shippingAddressId;
            },
            billingAddressId: function() {
                return this.cart.billingAddressId;
            },
            pickupLocationId: function() {
                return null;
            }
        },
        watch: {
            shippingAddressId: function(newValue) {
                this.newShippingAddress = newValue === null ? true : false;
            },
            billingAddressId: function(newValue) {
                this.newBillingAddress = newValue === null ? true : false;
            }
        },
        mounted() {
            let vm = this;
            // Set language
            const language = document.documentElement.lang;
            if (language) {
                window.language = language;
                localize(language);
            } else {
                console.error('language not found.');
            }
            // Select all fields with data attributes
            let fields = document.querySelectorAll('[data-initial]');
            if (fields.length) {
                fields.forEach(function (element) {
                    vm[element.name] = vm.$refs[element.name].dataset.initial;
                })
            }

            window.lozadObserver.observe();
            VueCookies.config('7d');
            this.cookieConsented = VueCookies.get('cookieConsent');

        },
        created() {
            this.$store.dispatch('getCart');
            this.getCart();
        }
    });
};
// Execute async function
main().then((value) => {

    for (const el of document.querySelectorAll(".glide--slider")) {
        new Glide(el, {
            type: 'slider',
            autoplay: 3500,
            hoverpause: true,
            animationDuration: 1000
        }).mount({ Controls, Breakpoints, Swipe, Autoplay })
    }

    for (const el of document.querySelectorAll(".glide--carousel")) {
        new Glide(el, {
            type: 'carousel',
            perView: 1,
            focusAt: 'center',
            autoplay: 3500,
            hoverpause: true,
            animationDuration: 1000,
            breakpoints: {
                800: {
                    perView: 1
                }
            }
        }).mount({ Controls, Breakpoints, Swipe, Autoplay })
    }

    if(document.querySelectorAll(".accordion").length) {
        new Accordion('.accordion', {
            elementClass: 'accordion__block', // element class {string}
            questionClass: 'accordion__header', // question class {string}
            answerClass: 'accordion__content', // answer class {string}
        });
    }

    const toggle_button = document.getElementById('menu-toggle')
    const toggled_menu = document.getElementById('menu-toggled')
    const toggle_menu = () => toggled_menu.classList.toggle('menu--active')
    if(toggle_button !== null) {
        toggle_button.addEventListener("click", function(e) { 
            toggle_menu()
        })
    }

    // Billing Same As Shipping checkbox
    const toggle_address = document.getElementById('billingAddressSameAsShipping')
    // Billing address container
    const toggled_address = document.getElementById('billingAddress')
    if(toggle_address !== null) {
        toggle_address.addEventListener("change", function(e) { 
            // checkbox checked
            if(e.target.checked) {
                // hide Billing address container
                toggled_address.classList.add('hidden')
                // remove required attr from inputs new Billing address form
                for (const input of toggled_address.querySelectorAll('.required')) {
                    input.required = false
                }
                // remove required attr from radios to choose Billing address
                for (const input of toggled_address.querySelectorAll('.addresses__toggle')) {
                    input.required = false
                }
            // checkbox not checked
            } else {
                // show Billing address container
                toggled_address.classList.remove('hidden')
                // add required attr to inputs new Billing address form
                for (const input of toggled_address.querySelectorAll('.required')) {
                    input.required = true
                }
                // add required attr to radios to choose Billing address
                for (const input of toggled_address.querySelectorAll('.addresses__toggle')) {
                    input.required = true
                }
            }
        })
    }
    
    // for all address containers (Shipping and Billing)
    for (const el of document.querySelectorAll('.addresses')) {
        // radio New address
        const toggle_form = el.querySelector('.addresses__toggle-form')
        // New address form container
        const toggled_form = el.querySelector('.addresses__form')
        if(toggle_form !== null) {
            toggle_form.addEventListener("change", function(e) { 
                // radio New address checked
                if(e.target.checked) {
                    // add required attr to inputs New address form
                    for (const input of toggled_form.querySelectorAll('.required')) {
                        input.required = true
                    }
                    // show New address form
                    toggled_form.classList.remove('hidden')
                // radio New address not checked
                } else {
                    // remove required attr from inputs New address form
                    for (const input of toggled_form.querySelectorAll('.required')) {
                        input.required = false
                    }
                    // hide New address form
                    toggled_form.classList.add('hidden')
                }
            })
            // loop radios to choose address
            for (const toggle of el.querySelectorAll('.addresses__toggle')) {
                toggle.addEventListener("change", function(e) { 
                    // radio checked
                    if(e.target.checked) {
                        // remove required attr from inputs New address form
                        for (const input of toggled_form.querySelectorAll('.required')) {
                            input.required = false
                        }
                        // hide New address form
                        toggled_form.classList.add('hidden')
                    // radio not checked
                    } else {
                        // show New address form
                        toggled_form.classList.remove('hidden')
                        // add required attr to inputs New address form
                        for (const input of toggled_form.querySelectorAll('.required')) {
                            input.required = true
                        }
                    }
                })
            }
        }
    }

    // pickup toggle
    for (const toggle of document.querySelectorAll('.pickup__toggle')) {
        toggle.addEventListener("change", function(e) { 
            for (const panel of document.querySelectorAll('.addresses__panel--new')) {
                const toggled_form = panel.querySelector('.addresses__data__form')
                const toggled_label = panel.querySelector('.addresses__data__label')
                // show the label
                toggled_label.classList.remove('hidden')
                // remove required attr from inputs New address form
                for (const input of toggled_form.querySelectorAll('.required')) {
                    input.required = false
                }
                // hide the form
                toggled_form.classList.add('hidden')
            }
            if(e.target.checked) {
                for (const input of document.getElementsByName('shippingAddressId')) {
                    input.value = e.target.dataset.shippingaddressid
                }
                for (const input of document.getElementsByName('fields[pickupLocation][0]')) {
                    input.value = e.target.dataset.pickuplocation
                }
                for (const input of document.getElementsByName('fields[preferredShop][0]')) {
                    input.value = e.target.dataset.preferredshop
                }
            }
        })
    }
    // shipping address toggle
    for (const toggle of document.querySelectorAll('.shipping-address__toggle')) {
        toggle.addEventListener("change", function(e) { 
            // radio checked
            if(e.target.checked) {
                for (const input of document.getElementsByName('shippingAddressId')) {
                    input.value = e.target.dataset.addressid
                }
                for (const input of document.getElementsByName('fields[pickupLocation][0]')) {
                    input.value = e.target.dataset.pickuplocation
                }
                for (const input of document.getElementsByName('fields[preferredShop][0]')) {
                    input.value = e.target.dataset.preferredshop
                }
            }
            for (const panel of document.querySelectorAll('.addresses__panel--new')) {
                const toggled_form = panel.querySelector('.addresses__data__form')
                const toggled_label = panel.querySelector('.addresses__data__label')
                // show the label
                toggled_label.classList.remove('hidden')
                // remove required attr from inputs New address form
                for (const input of toggled_form.querySelectorAll('.required')) {
                    input.required = false
                }
                // hide the form
                toggled_form.classList.add('hidden')
            }
        })
    }
    // new shipping address toggle
    for (const panel of document.querySelectorAll('.addresses__panel--new')) {
        const toggle_form = panel.querySelector('.shipping-address__toggle-new')
        const toggled_form = panel.querySelector('.addresses__data__form')
        const toggled_label = panel.querySelector('.addresses__data__label')
        if(toggle_form !== null) {
            toggle_form.addEventListener("change", function(e) { 
                // radio checked
                if(e.target.checked) {
                    for (const input of document.getElementsByName('shippingAddressId')) {
                        input.value = e.target.dataset.addressid
                    }
                    for (const input of document.getElementsByName('fields[pickupLocation][0]')) {
                        input.value = e.target.dataset.pickuplocation
                    }
                    for (const input of document.getElementsByName('fields[preferredShop][0]')) {
                        input.value = e.target.dataset.preferredshop
                    }
                    // hide the label
                    toggled_label.classList.add('hidden')
                    // show the form
                    toggled_form.classList.remove('hidden')
                    // add required attr to inputs New address form
                    for (const input of toggled_form.querySelectorAll('.required')) {
                        input.required = true
                    }
                }else{
                    // show the label
                    toggled_label.classList.remove('hidden')
                    // remove required attr from inputs New address form
                    for (const input of toggled_form.querySelectorAll('.required')) {
                        input.required = false
                    }
                    // hide the form
                    toggled_form.classList.add('hidden')
                }
            })
        }
    }

});